export function SET_USER(state, user) {
  state.user = user.user;
  state.isLogged = true;
  state._id = user._id;
  state.unique_id = user.unique_id;
  state.is_assist_mode = user.is_assist_mode;
}

export function LOGOUT(state) {
  state.user = null;
  state.isLogged = false;
  state.token = null;
  state._id = null;
  state.unique_id = null;
  state.email = null;
  state.name = null;
  state.countryName = null;
  state.phone = null;
  state.countryPhoneCode = null;
  state.wallet = null;
  state.overdraft = null;
  state.picture_url = null;
  state.pre_time = null;
  state.is_active_shipments = null;
  state.is_active_multi_stops = null;
  state.is_corporate_master = null;
  state.is_used_google_maps_service = null;
  state.is_allowed_corporate_api_partner = null;
  state.is_corporate_api_partner = null;
  state.is_allowed_corporate_shipment_api = null;
  state.is_assist_mode = null;
}

export function SET_TOKEN(state, token) {
  state.token = token;
}

export function SET_ME(state, me) {
  state.email = me.email;
  state.name = me.name;
  state.countryName = me.countryName;
  state.phone = me.phone;
  state.countryPhoneCode = me.countryPhoneCode;
  state.wallet = me.wallet;
  state.overdraft = me.overdraft;
  state.pre_time = me.pre_time;
  state.admin_phone = me.admin_phone;
  state.admin_email = me.admin_email;
  state.picture_url = me.picture_url;
  state.is_active_shipments = me.is_active_shipments;
  state.is_active_multi_stops = me.is_active_multi_stops;
  state.is_corporate_master = me.is_corporate_master;
  state.is_used_google_maps_service = me.is_used_google_maps_service;
  state.is_allowed_corporate_api_partner = me.is_allowed_corporate_api_partner;
  state.is_corporate_api_partner = me.is_corporate_api_partner;
  state.is_allowed_corporate_shipment_api = me.is_allowed_corporate_shipment_api;
}

export function SET_LANG(state, lang) {
  state.lang = lang;
}
