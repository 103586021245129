import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("@/views/Landing/Landing.vue"),
    meta: {
      layout: "blank",
      title: "Landing",
    },
  },
  {
    path: "/contactanos",
    name: "contact",
    component: () => import("@/views/Landing/Contact.vue"),
    meta: {
      layout: "blank",
      title: "Contáctanos",
    },
  },
  {
    path: "/dashboards",
    name: "dashboards",
    component: () => import("@/views/Dashboards/Dashboards.vue"),
    meta: {
      layout: "content",
      title: "Dashboards",
      requiresAuth: true,
    },
  },
  {
    path: "/running_trips",
    name: "runningTrips",
    component: () => import("@/views/RunningTrips/RunningTrips.vue"),
    meta: {
      layout: "content",
      title: "Running trips",
      requiresAuth: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/multistop",
    name: "multiStop",
    component: () => import("@/views/Multistop/Multistop.vue"),
    meta: {
      layout: "content",
      title: "Multistop",
      requiresAuth: true,
      requiresMultiStop: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/multistop/new",
    name: "multiStopNew",
    component: () => import("@/views/Multistop/actions/Edit.vue"),
    meta: {
      layout: "content",
      title: "multiStopNew",
      requiresAuth: true,
      requiresMultiStop: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/multistop/:id",
    name: "multiStopShow",
    component: () => import("@/views/Multistop/actions/Show.vue"),
    meta: {
      layout: "content",
      title: "MultistopShow",
      requiresAuth: true,
      requiresMultiStop: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: () => import("@/views/History/History.vue"),
    meta: {
      layout: "content",
      title: "History",
      requiresAuth: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/running_parcels",
    name: "runningParcels",
    component: () => import("@/views/Parcel/RunningParcels.vue"),
    meta: {
      layout: "content",
      title: "Running parcels",
      requiresAuth: true,
      requiresParcel: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/parcel_history",
    name: "parcelHistory",
    component: () => import("@/views/ParcelHistory/History.vue"),
    meta: {
      layout: "content",
      title: "parcelHistory",
      requiresAuth: true,
      requiresParcel: true,
      forbiddenMaster: true,
    },
  },
  // {
  //   path: "/parcel_multistop",
  //   name: "parcelMultiStop",
  //   component: () => import("@/views/ParcelMultistop/ParcelMultistop.vue"),
  //   meta: {
  //     layout: "content",
  //     title: "ParcelMultistop",
  //     requiresAuth: true,
  //     requiresParcel: true,
  //   },
  // },
  // {
  //   path: "/parcel_multistop/new",
  //   name: "parcelMultiStopNew",
  //   component: () => import("@/views/ParcelMultistop/actions/Edit.vue"),
  //   meta: {
  //     layout: "content",
  //     title: "parcelMultiStopNew",
  //     requiresAuth: true,
  //     requiresParcel: true,
  //   },
  // },
  {
    path: "/parcel_multistop/:id",
    name: "parcelMultiStopShow",
    component: () => import("@/views/ParcelMultistop/actions/Show.vue"),
    meta: {
      layout: "content",
      title: "parcelMultiStopShow",
      requiresAuth: true,
      requiresParcel: true,
    },
  },
  {
    path: "/corporate_users",
    name: "users",
    component: () => import("@/views/Users/Users.vue"),
    meta: {
      layout: "content",
      title: "Users",
      requiresAuth: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/corporate_panels",
    name: "corporatePanels",
    component: () => import("@/views/CorporatePanels/CorporatePanels.vue"),
    meta: {
      layout: "content",
      title: "CorporatePanels",
      requiresAuth: true,
      requiresMaster: true,
    },
  },
  {
    path: "/balance",
    name: "balance",
    component: () => import("@/views/Balance/Balance.vue"),
    meta: {
      layout: "content",
      title: "Balance",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/Profile/Profile.vue"),
    meta: {
      layout: "content",
      title: "Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/Faq/Faq.vue"),
    meta: {
      layout: "content",
      title: "Faq",
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "auth-login",
    component: () => import("@/views/Login/Login.vue"),
    meta: {
      layout: "blank",
      title: "Login",
    },
  },
  {
    path: "/invoice/:id",
    name: "invoice",
    component: () => import("@/views/Invoice/Invoice.vue"),
    meta: {
      layout: "content",
      title: "Invoice",
      requiresAuth: true,
    },
  },
  {
    path: "/user/:id",
    name: "user",
    component: () => import("@/views/User/User.vue"),
    meta: {
      layout: "content",
      title: "User",
      requiresAuth: true,
    },
  },
  {
    path: "/corporate_panel/:id",
    name: "corporatePanel",
    component: () => import("@/views/CorporatePanel/CorporatePanel.vue"),
    meta: {
      layout: "content",
      title: "CorporatePanel",
      requiresAuth: true,
      requiresMaster: true,
    },
  },
  {
    path: "/provider/:id",
    name: "provider",
    component: () => import("@/views/Provider/Provider.vue"),
    meta: {
      layout: "content",
      title: "Provider",
      requiresAuth: true,
    },
  },
  {
    path: "/view-map/:id",
    name: "ViewMap",
    component: () => import("@/views/ViewMap/ViewMap.vue"),
    meta: {
      layout: "content",
      title: "ViewMap",
      requiresAuth: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/ForgotPassword/ForgotPassword.vue"),
    meta: {
      layout: "blank",
      title: "ForgotPassword",
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword/ResetPassword.vue"),
    meta: {
      layout: "blank",
      title: "ResetPassword",
    },
  },
  {
    path: "*",
    name: "Error",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "blank",
      title: "Error",
    },
  },
  {
    path: "/not-in-panel",
    name: "NotInPanel",
    component: () => import("@/views/NotInPanel.vue"),
    meta: {
      layout: "blank",
      title: "Error",
    },
  },
  {
    path: "/forbidden",
    name: "Forbidden",
    component: () => import("@/views/ErrorForbidden.vue"),
    meta: {
      layout: "blank",
      title: "Forbidden",
    },
  },
  {
    path: "/parcel-guide",
    name: "ParcelGuide",
    component: () => import("@/views/ParcelGuide.vue"),
    meta: {
      layout: "blank",
      title: "ParcelGuide",
      requiresAuth: false,
    },
  },
  {
    path: "/trip-info/:id",
    name: "TripInfo",
    component: () => import("@/views/TripInfo/ViewMap.vue"),
    meta: {
      layout: "blank",
      title: "TripInfo",
      requiresAuth: false,
    },
  },
  {
    path: "/api-documentation",
    name: "ApiDocumentation",
    component: () => import("@/views/Api/Documentation.vue"),
    meta: {
      layout: "content",
      title: "ApiDocumentation",
      requiresAuth: true,
      requiresAPI: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/api-credentials",
    name: "ApiCredentials",
    component: () => import("@/views/Api/Credentials.vue"),
    meta: {
      layout: "content",
      title: "ApiCredentials",
      requiresAuth: true,
      requiresAPI: true,
      forbiddenMaster: true,
    },
  },
  {
    path: "/assist-mode",
    name: "Assist Mode",
    component: () => import("@/views/AssistMode/AssistMode.vue"),
    meta: {
      layout: "blank",
      title: "Login",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((_to, _from, next) => {
  const user = store.state.auth.user;
  const is_active_shipments = store.state.auth.is_active_shipments;
  const is_active_multi_stops = store.state.auth.is_active_multi_stops;
  const is_corporate_master = store.state.auth.is_corporate_master;
  const requiresAuth = _to.matched.some((route) => route.meta.requiresAuth);
  const requiresParcel = _to.matched.some((route) => route.meta.requiresParcel);
  const requiresMultiStop = _to.matched.some(
    (route) => route.meta.requiresMultiStop
  );
  const requiresMaster = _to.matched.some((route) => route.meta.requiresMaster);
  const forbiddenMaster = _to.matched.some(
    (route) => route.meta.forbiddenMaster
  );

  const is_corporate_api_partner = store.state.auth.is_corporate_api_partner;

  const requiresAPI = _to.matched.some((route) => route.meta.requiresAPI);

  // Authentication
  if (requiresAuth && !user) {
    return next({ name: "auth-login", query: { redirect: to.fullPath } });
  }

  if (requiresParcel && !is_active_shipments) {
    return next({ name: "Forbidden" });
  }

  if (requiresMultiStop && !is_active_multi_stops) {
    return next({ name: "Forbidden" });
  }

  if (requiresMaster && !is_corporate_master) {
    return next({ name: "Forbidden" });
  }

  if (forbiddenMaster && is_corporate_master) {
    return next({ name: "Forbidden" });
  }

  if (requiresAPI && !is_corporate_api_partner) {
    return next({ name: "Forbidden" });
  }

  next();
});

export default router;
